<template>
  <div class="user-manager">
    <div v-if="list && list.length > 0" class="list">
      <div v-for="item in list" :key="item.id" class="item">
        <div class="hd">
          <div class="avatar" @click="goSalesmanInfo(item)"><img :src="item.saleHeadImgUrlOss" alt=""></div>
          <div class="name">{{ item.realName }}</div>
          <div class="status" :class="{ doing: item.status == 1, success: item.status == 2, cancel: item.status == 3, fail: item.status == 5 }" @click="goDetail(item)">{{ item.statusText }} <van-icon name="arrow" class="i" /></div>
        </div>
        <div class="ct">
          <div class="it">
            <div class="icon"><img :src="icon2" alt=""></div>
            <div class="title">贷款金额</div>
            <div class="num">{{ item.loan }}万</div>
          </div>
          <div class="it">
            <div class="icon"><img :src="icon1" alt=""></div>
            <div class="title">贷款方式</div>
            <div class="num">{{ item.creditTypeTitle }}</div>
          </div>
        </div>
        <div class="bt">
          <div v-if="!againStatus" class="btn btn-primary" @click="goAgain(item)">再贷一次</div>
          <div v-if="item.status == 1" class="btn btn-primary" @click="goChat(item)">继续沟通</div>
          <template v-if="item.status == 2">
            <div v-if="item.hasComment == 0" class="btn" @click="goComment(item)">去评价</div>
            <div v-else class="btn" @click="goSalesmanInfo(item)">已评价</div>
          </template>
        </div>
      </div>
    </div>
    <div v-else-if="list" class="emprty">
      <DefaultPage />
    </div>
  </div>
</template>
<script>
import icon1 from '@/assets/user/loan_type@2x.png'
import icon2 from '@/assets/user/loan_price@2x.png'
import { getMyManagerPersonal } from '@/api/product'
import { checkCity } from '@/api/user'
import { getOssUrl } from '@/utils/oss'
import DefaultPage from '@/components/DefaultPage'
export default {
  components: { DefaultPage },
  data() {
    return {
      icon1,
      icon2,
      list: null,
      againStatus: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    goDetail(item) {
      if (item.status === 3) {
        this.$toast('您还未确认方案，无法查看订单详情!')
      }
      if (item.status === 2 || item.status === 5 || item.status === 1) {
        if (item.isSelectedPlan === 1) {
          this.$dialog.confirm({
            title: '提示',
            message: '您当前还未选定贷款方案，请先与经理人确定贷款方案',
            confirmButtonText: '去确认'
          })
            .then(async() => {
              this.$router.push({ name: 'chatRoom', params: { your: item.eaUserName }})
            })
            .catch(() => {
              // on cancel
            })
        } else {
          this.$router.push({ name: 'loanProgress', params: { orderId: item.orderId }, query: { saleId: item.saleId }})
        }
      }
    },
    goComment(item) {
      this.$router.push({ name: 'userComment', params: { details: item }})
    },
    goSalesmanInfo(item) {
      this.$router.push({ name: 'salesmanInfo', params: { saleId: item.saleId }})
    },
    goChat(item) {
      this.$router.push({ name: 'chatRoom', params: { your: item.eaUserName }})
    },
    async goAgain(item) {
      const res = await checkCity({ saleId: item.saleId })
      if (res.datas === 1) {
        this.$dialog.confirm({
          title: '前去确认贷款信息',
          message: '温馨提示：再次贷款会默认选定当前经理人',
          confirmButtonText: '去确认'
        })
          .then(async() => {
            // on confirm
            this.$router.push({ name: 'loanEdit', query: { lastOrderId: item.orderId, salesmanId: item.saleId }})
          })
          .catch(() => {
            // on cancel
          })
      } else {
        // 该城市未开放处理
      }
    },
    async getList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getMyManagerPersonal()
      loading.clear()
      const list = res.datas || []
      for (let i = 0; i < list.length; i++) {
        this.$set(list[i], 'saleHeadImgUrlOss', await getOssUrl(list[i].saleHeadImgUrl))
        if (list[i].status === 1) {
          this.againStatus = true
        }
      }
      this.list = list
    }
  }
}
</script>
<style lang="scss" scoped>
.user-manager {
  padding: 10px;
  .list {
    .item {
      padding: 10px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
      border-radius: 8px;
      background: #fff;
      .hd {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F3F3F5;
        .avatar {
          width: 32px;
          height: 32px;
        }
        .name {
          flex: 1;
          padding-left: 10px;
          font-size: 16px;
        }
        .status {
          font-size: 14px;
          color: #2697FF;
          &.success {
            color: #18C47C
          }
          &.fail {
            color: #F86767;
          }
          &.cancel {
            color: #999999;
          }
        }
        .i {
          color: #cccccc;
        }
      }
      .ct {
        display: flex;
        padding: 15px 0 20px 0;
        .it {
          display: flex;
          align-items: center;
          flex: 1;
          .icon {
            width: 16px;
            height: 16px;
            img {
              width: 16px;
              height: 16px;
            }
          }
          .title {
            padding: 0 10px 0 5px;
            font-size: 14px;
            color: #999999;
          }
        }
      }
      .bt {
        display: flex;
        justify-content: flex-end;
        .btn {
          color: #2E3135;
          font-size: 12px;
          width: 70px;
          height: 30px;
          line-height: 30px;
          margin-left: 10px;
          text-align: center;
          border: 1px solid #E6E6E6;
          border-radius: 16px;
          &.btn-primary {
            color: #f17a12;
            border: 1px solid #f17a12;
          }
        }
      }
    }
  }
}
</style>
